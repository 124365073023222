import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import { doNothingAnchor } from "../../components/Old2005BlogPage";
import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—October 2009" />
	<NewsHeader />
    <h2>October 2009</h2>



	<h3 className="blogdate">
	Sunday, October 18, 2009
	</h3>

	<h4 className="blogitemtitle">
	Arm's Length Away, 5 day preview
	</h4>
		<div className="blogitembody">
		<p>What does Jesse sound like in a church? Here's a 5-day-glimpse of one the
	songs that launched the World March for Peace and Nonviolence on October
	2, purpose-built by yours truly and complete with new-song-chord-fumbles
	and that big church echo. It's called <span><i>Arm's Length Away</i></span>,
	and I'll leave it up as last song on my Myspace page until Friday night
	(Oct 23) New Zealand time.</p>

	<ul>
	<li>
	<a href="/" onClick={doNothingAnchor}>(long since removed)</a>
	</li>
	<li>
	<a href="/" onClick={doNothingAnchor}>(long since removed)</a>
	</li>
	</ul>
	<p>Enjoy!</p>

	<p>
	Jesse
	</p>
		</div>
				<div className="blogitemfooter">
		<p>posted by Jesse @ 21:47 +1300</p>
		</div>


	<h3 className="blogdate">
	Sunday, October 04, 2009
	</h3>

	<h4 className="blogitemtitle">
	Stu Pedley to join Wellington show
	</h4>
		<div className="blogitembody">
		<p>I'm looking forward to my show in Wellington next Friday - it's gonna be
	fun to have Stu Pedley join me with his mandolin on several songs. I've
	picked a nice little licensed venue that we can easily fill up, especially
	if you bring a friend, ha ha. Or a date... Yes, bring a date and I will
	save a romantic song for last. Oh, and early birds get the best seats!</p>

	<p>
	As some of you know, I have a little video camera and I plan to use it -
	I'll somehow video record this show while I'm playing. I've just been
	down to the &quot;pimp my body&quot; shop to get an extra arm attached to my waist
	and this week I'll be learning to use it.
	</p>
	<p>
	Here are the details:
	</p>
	<ul>
	<li>
	<a href="http://www.facebook.com/event.php?eid=168686845029">Facebook
	event page</a> (invite your friends, don't be selfish)
	</li>
	<li>
	<b><span>Friday, October 9</span></b> @ <span><b>8:00 pm</b></span>
	(doors 7:30, $6 entry), @ <span><b>Mojo Invincible</b></span>, 161
	Willis Street, Wellington, New Zealand
	</li>
	</ul>
	<p>Ciao for now,</p>

	<p>
	Jesse
	</p>
		</div>
				<div className="blogitemfooter">
		<p>posted by Jesse @ 00:00 +1300</p>
		</div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
